import React from "react";
import Header from "../components/Header";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../constants/config";
import { useEffect } from "react";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import Footer from "../components/Footer";

export default function Events() {
  const [events, setEvents] = useState([]);
  const EventsData = () => {
    axios
      .get(`${BASE_URL}/api/events/getEvents`)
      .then((res) => {
        setEvents(res.data.events);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isMobile = useMediaQuery("(max-width:850px)");

  useEffect(() => {
    EventsData();
  }, []);

  const largeDisplay = useMediaQuery("(min-width:1000px)");

  return (
    <section>
      <Header />

      {/* <div
        style={{
          position: "relative",
          width: "100%",
          height: "90vh",
          marginBottom: "20px",
        }}
      >
        <img
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          src={require("../assets/images/confer.jpg")}
          alt=""
        />

        <h1
          style={{
            position: "absolute",
            bottom: "20px",
            color: "white",
            left: "50%",
            transform: "translateX(-50%)",
            textAlign: "center",
            fontWeight: "500",
            fontSize: "42px",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
          }}
          className="events-heading"
        >
          Events
        </h1>
      </div> */}
      <div className="page-2" style={{ background: "#01041e" }}>
        <div class="content">
          <div class="heading" data-aos="fade-up">
            <h1
              data-aos="fade-right"
              style={{ fontSize: largeDisplay ? "42px" : "30px" }}
            >
              Events
            </h1>
          </div>
        </div>
      </div>

      <div
        style={{
          margin: "200px 0 0 0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "white",
            textAlign: "center",
            maxWidth: "75%",
            fontSize: "16px",
            fontWeight: "bold",
            lineHeight: "24px",
          }}
        >
          As a part of its commitement to global networking, the Prolog team
          frequently travels globally to attend all major events and meet
          members in their home markets. These efforts enable us to better
          understand the needs and challenges of our members, as well as to
          provide personalized support and guidance.
          <br />
          <br />
          By engaging with members directly our team can deepen connections,
          build trust, and gain valuable insights into regional dynamics and
          industry trends. This approach reinforces our status as a truly global
          network that values collaboration, communication, and partnership.
        </p>

        <div style={{ margin: "100px 20%" }}>
          {events?.map((ev, index) => {
            return (
              <div
                key={ev.id}
                style={{
                  borderRadius: "20px",
                  border: "1px solid #2f65c1",
                  background: "rgba(47, 101, 193, 0.3)",
                  position: "relative",
                  cursor: "pointer",
                  // padding: "20px",
                  transition: "all 0.3s ease-in-out",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: "75px 0px",
                }}
                className="event-card"
              >
                <div style={{ width: "30%" }}>
                  {/* {ev.logo === "" ? (
                    <img
                      src={require("../assets/images/home/semi-exclusive.png")}
                      alt=""
                      style={{ width: "90%", height: "100%" }}
                    />
                  ) : (
                    )} */}
                  <img
                    src={ev.logo}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      borderBottomLeftRadius: "20px",
                      borderTopLeftRadius: "20px",
                    }}
                  />
                </div>
                <div style={{ width: "65%", padding: "5px 20px" }}>
                  <h4
                    style={{
                      color: "#fff",
                      fontFamily: "Montserrat",
                      fontSize: "20px",
                      fontWeight: 600,
                      padding: "10px 0 0 0",
                    }}
                  >
                    {ev.heading}
                  </h4>
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "lightblue",
                      padding: "5px 0",
                    }}
                  >
                    {ev.venue +
                      " " +
                      moment(ev.start_date).format("DD MMM") +
                      " - " +
                      moment(ev.end_date).format("DD MMM YYYY")}
                  </h6>
                  <p
                    style={{
                      color: "#fff",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontWeight: "400",
                      textAlign: "left",
                      padding: "0px",
                      overflow: "hidden",
                    }}
                  >
                    {ev.description}
                  </p>
                  <a
                    href="#"
                    class="event-card-a"
                    style={{ color: "#2f65c1", fontFamily: "Montserrat" }}
                    onClick={() => window.open(ev.url, "_blank")}
                  >
                    Learn More<i class="bx bx-right-arrow-alt"></i>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </section>
  );
}
