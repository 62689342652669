import { Drawer, useMediaQuery } from "@mui/material";
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { testimonials } from "../assets/data";
import CSSLink from "../components/CSSLink";
import Header from "../components/Header";
import Menu from "./Menu";
import Footer from "../components/Footer";

const Testimonial = () => {
  const [openMenu, setopenMenu] = useState(false);

  const largeDisplay = useMediaQuery("(min-width:1000px)");
  const smallScreen = useMediaQuery("(max-width: 767px)");

  const CustomPrevButton = (props) => {
    const { onClick } = props;
    return (
      <button
        className="custom-prev-button"
        style={{
          outline: "none",
          left: "-2.5%",
          top: smallScreen ? "90%" : "50%",
        }}
        onClick={onClick}
      >
        <img
          src={require("../assets/images/arrow-left.png")}
          alt=""
          onClick={() => sliderRef.current.slickNext()}
        />
      </button>
    );
  };

  const CustomNextButton = (props) => {
    const { onClick } = props;
    return (
      <button
        className="custom-next-button"
        style={{
          outline: "none",
          right: "1.5%",
          top: smallScreen ? "90%" : "50%",
        }}
        onClick={onClick}
      >
        <img src={require("../assets/images/arrow-right.png")} alt="" />
      </button>
    );
  };

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <CustomPrevButton />,
    nextArrow: <CustomNextButton />,
    dots: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderRef = useRef(null);

  return (
    <section>
      <CSSLink />
      <Header open={openMenu} setOpen={setopenMenu} main={false} />
      <div class="page-10">
        <Drawer
          anchor={"left"}
          open={openMenu}
          PaperProps={{
            sx: { width: "100%" },
          }}
          onClose={() => setopenMenu(false)}
        >
          <Menu open={openMenu} setOpen={setopenMenu} />
        </Drawer>

        <main>
          <div class="contents">
            <div class="heading">
              <h1 style={{ fontSize: largeDisplay ? "42px" : "30px" }}>
                Testimonials
              </h1>
            </div>

            <div class="content">
              <Slider
                {...settings}
                className="testimonial"
                ref={sliderRef}
                // style={{ width: "80% !important" }}
              >
                {testimonials.map((item, index) => {
                  return (
                    <div style={{ width: "100%", position: "relative" }}>
                      <div class="img-txt">
                        <h2>From The Members</h2>
                        <img
                          src={item.logo}
                          alt=""
                          style={{
                            width: "208px",
                            height: "174px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <div class="para">
                        <p
                          style={{
                            fontWeight: "600",
                            color: "#fff",
                          }}
                        >
                          {item.comment}
                        </p>
                      </div>
                      <div class="content-bottom">
                        <img
                          class="starr"
                          src={require("../assets/images/stars2.png")}
                          alt=""
                        />
                        <div class="name">
                          <h2>{item.author}</h2>
                          <h3>{item.designation}</h3>
                          <img
                            src={item.flag}
                            alt=""
                            style={{
                              width: "58px",
                              height: "39px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </main>
      </div>

      <Footer />
    </section>
  );
};

export default Testimonial;
