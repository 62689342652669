import React, { useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { BASE_URL } from "../constants/config";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import Footer from "../components/Footer";

const TeamComp = ({ item, index, setmemberData, handleOnClick }) => {
  const Small = useMediaQuery("(max-width:850px)");
  const isMobile = useMediaQuery("(max-width:650px)");
  return (
    <div
      style={{
        borderRadius: "20px",
        border: "1px solid #2f65c1",
        background: "rgba(47, 101, 193, 0.3)",
        position: "relative",
        transition: "all 0.3s ease-in-out",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "75px 25px",
        height: "350px",
        width: isMobile ? "50%" : Small ? "40%" : "20%",
        cursor: "pointer",
      }}
      key={index}
      onClick={() => {
        console.log("kajdslk");
        setmemberData(item);
        handleOnClick();
      }}
    >
      <div style={{ height: "200px" }}>
        <img
          src={item?.avatar}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "conttain",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        />
      </div>

      <div style={{ padding: "15px", width: "100%", height: "150px" }}>
        <h4
          style={{
            color: "#fff",
            fontFamily: "Montserrat",
            fontSize: Small ? "20px" : "24px",
            fontWeight: 600,
            padding: "10px 0 0 0",
            maxWidth: "100%",
          }}
        >
          {item?.firstName + " " + item?.lastName}
        </h4>
        <h6
          style={{
            fontWeight: "bold",
            color: "lightblue",
            padding: "5px 0",
            fontSize: "14px",
          }}
        >
          {item?.designation}
        </h6>
        {/* <p
          style={{
            color: "#fff",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: "400",
            textAlign: "left",
            padding: "0px",
            overflow: "hidden",
          }}
        >
          {item.description}
        </p> */}
      </div>
    </div>
  );
};

export default function OurTeam() {
  const [team, setTeam] = useState([]);

  const GetTeamData = () => {
    axios
      .get(`${BASE_URL}/api/ourTeam/getTeam`)
      .then((res) => {
        setTeam(res.data.team);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isMobile = useMediaQuery("(max-width:850px)");

  useEffect(() => {
    GetTeamData();
  }, []);

  const largeDisplay = useMediaQuery("(min-width:1000px)");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [memberData, setmemberData] = useState({});
  return (
    <>
      <Header />

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "10px",
            background: "#0F214F",
          },
        }}
      >
        <DialogContent>
          <Box>
            <Stack direction="row">
              <img
                src={memberData?.avatar}
                alt=""
                style={{
                  width: "150px",
                  height: "150px",
                  borderBottomLeftRadius: "75px",
                  borderBottomRightRadius: "75px",
                  borderTopLeftRadius: "75px",
                  borderTopRightRadius: "75px",
                }}
              />
              <Box sx={{ ml: 2 }}>
                <Typography
                  variant="h4"
                  style={{
                    color: "#fff",
                    fontFamily: "Montserrat",
                    fontSize: "24px",
                    fontWeight: 600,
                    padding: "10px 0 0 0",
                    maxWidth: "100%",
                  }}
                >
                  {memberData?.firstName + " " + memberData?.lastName}
                </Typography>
                <h6
                  style={{
                    fontWeight: "bold",
                    color: "lightblue",
                    padding: "5px 0",
                    fontSize: "14px",
                  }}
                >
                  {memberData?.designation}
                </h6>
                <Typography
                  variant="h5"
                  style={{
                    color: "#fff",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginTop: "-5px",
                    marginBottom: "5px",
                  }}
                >
                  {memberData?.country}
                  {", "} {memberData?.city}
                </Typography>
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: "400",
                    textAlign: "left",
                    padding: "5px 0px",
                    overflow: "hidden",
                  }}
                >
                  {memberData?.description}
                </p>
              </Box>
            </Stack>
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions> */}
      </Dialog>
      <div className="page-2" style={{ background: "#01041e" }}>
        <div class="content">
          <div class="heading" data-aos="fade-up">
            <h1
              data-aos="fade-right"
              style={{ fontSize: largeDisplay ? "42px" : "30px" }}
            >
              Our Team
            </h1>
          </div>
        </div>
      </div>

      <div
        style={{ flexWrap: "wrap", display: "flex", justifyContent: "center" }}
      >
        {team
          ?.sort((a, b) => a?.position - b?.position)
          ?.map((item, index) => {
            return (
              item?.position === 1 && (
                <TeamComp
                  item={item}
                  index={index}
                  setmemberData={setmemberData}
                  handleOnClick={handleClickOpen}
                />
              )
            );
          })}
      </div>
      <div
        style={{ flexWrap: "wrap", display: "flex", justifyContent: "center" }}
      >
        {team
          ?.sort((a, b) => a?.position - b?.position)
          ?.map((item, index) => {
            return (
              item?.position !== 1 && (
                <TeamComp
                  item={item}
                  index={index}
                  setmemberData={setmemberData}
                  handleOnClick={handleClickOpen}
                />
              )
            );
          })}
      </div>

      <Footer />
    </>
  );
}
