import {
  Backdrop,
  CircularProgress,
  Drawer,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import CSSLink from "../components/CSSLink";
import Header from "../components/Header";
import { BASE_URL } from "../constants/config";
import Menu from "./Menu";
import MuiAlert from "@mui/material/Alert";
import { useForm } from "react-hook-form";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InsuranceInquiry = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [message, setMessage] = useState("");

  const [isPro, setIsPro] = useState(false);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [erroropen, setErrorOpen] = useState(false);
  const [emailError, setEmailError] = useState("");
  const clear = () => {
    setFirstName("");
    setLastName("");
    setCompanyName("");
    setEmail("");
    setDesignation("");
    setCountry("");
    setCity("");
    setIsPro(false);
    setMessage("");
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const OnSubmit = () => {
    setloading(true);
    if (firstName == "" || email == "" || lastName == "" || companyName == "") {
      setloading(false);
      setMessage("Please Fill the required fields.");
      setErrorOpen(true);
      return;
    }
    axios
      .post(`${BASE_URL}/api/insurance_inquiry/create`, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        designation: designation,
        companyName: companyName,
        country: country,
        city: city,
        isPro: isPro,
        status: "",
      })
      .then((res) => {
        setloading(false);
        setOpen(true);
        clear();
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
        setOpen(false);
      });
  };

  const [openMenu, setopenMenu] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOpen(false);
  };

  const smallScreen = useMediaQuery("(max-width: 767px)");
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const test = regex.test(email);
    if (test) {
      setEmailError(""); // Clear error if valid
    } else {
      setEmailError("Invalid email format"); // Set error message if invalid
    }
  };
  return (
    <section>
      <CSSLink />
      <Header />
      <div class="page-13">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            src={require("../assets/images/loader.gif")}
            style={{ height: "175px", width: "175px" }}
            alt=""
          />
        </Backdrop>
        <Drawer
          anchor={"left"}
          open={openMenu}
          PaperProps={{
            sx: { width: "100%" },
          }}
          onClose={() => setopenMenu(false)}
        >
          <Menu open={openMenu} setOpen={setopenMenu} />
        </Drawer>

        <Snackbar
          open={erroropen}
          autoHideDuration={3000}
          onClose={handleCloseError}
        >
          <Alert
            onClose={handleCloseError}
            severity="error"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Thank you for providing the information. Our PRO-Tect team will be
            in touch with you to guide you further, if you qualify.
          </Alert>
        </Snackbar>

        <main>
          <div class="contents" style={{ marginBottom: "100px" }}>
            <div
              class="header-logo"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            >
              <img src={require("../assets/images/protectBadge1.png")} alt="" />
              <img src={require("../assets/images/protectBadge2.png")} alt="" />
              <img src={require("../assets/images/protectBadge3.png")} alt="" />
            </div>
            <h1
              style={{
                marginTop: smallScreen ? "50px" : "10px",
                color: "#fff",
              }}
            >
              Pro-tect
            </h1>
            <h6
              style={{
                marginTop: smallScreen ? "50px" : "0px",
                color: "#fff",
              }}
            >
              Please tell us more to create your login on the Thames Marine Self
              Help Portal
            </h6>
            <div class="row">
              <div
                class="col-lg-6 col-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="text-input">
                  <input
                    type="text"
                    placeholder="First Name*"
                    name="firstName"
                    {...register("firstName", { required: true })}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="text-input">
                  <input
                    type="text"
                    placeholder="Last Name*"
                    name="lastName"
                    {...register("lastName", { required: true })}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-lg-6 col-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="text-input">
                  <input
                    type="email"
                    placeholder="Email*"
                    name="email"
                    {...register("email", {
                      required: true,
                    })}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      validateEmail(e.target.value);
                    }}
                  />
                  {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                </div>
              </div>
              <div
                class="col-lg-6 col-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="text-input">
                  <input
                    type="text"
                    placeholder="Company*"
                    name="company"
                    {...register("company", { required: true })}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-lg-6 col-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="text-input">
                  <input
                    type="text"
                    placeholder="Designation"
                    name="designation"
                    {...register("designation", { required: false })}
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="text-input">
                  <input
                    type="text"
                    placeholder="Country"
                    name="country"
                    {...register("country", { required: false })}
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-lg-6 col-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div class="text-input">
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    {...register("city", { required: false })}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>
              <div
                class="col-lg-6 col-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  class="text-input"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p style={{ color: "#fff", margin: 0, whiteSpace: "nowrap" }}>
                    Are you already a Pro Member?
                  </p>
                  <input
                    type="checkbox"
                    name="isPro"
                    {...register("isPro", { required: false })}
                    onChange={(e) => setIsPro(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* <div class="privacy">
              <p style={{ textAlign: "center" }}>
                By clicking Submit, you acknowledge Prolognet Terms <br />
                of Service & Privacy Policy.
              </p>
            </div> */}

            {/* <button
                style={{
                  display: "inline-block",
                  position: "relative",
                  padding: "0",
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
                onClick={handleSubmit(OnSubmit)}
              >
                <img
                  src={require("../assets/images/arrow-down.png")}
                  style={{
                    display: "block",
                    width: "500px",
                    height: "150px",
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    top: "35%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "#ffffff",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Submit
                </span>
              </button> */}
            <a
              href="#"
              class="themeBtn-light"
              style={{
                width: smallScreen ? "200px" : "480px",
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                marginTop: smallScreen ? "20px" : "20px",
              }}
              onClick={OnSubmit}
            >
              Submit
            </a>
          </div>
        </main>
      </div>

      <Footer />
    </section>
  );
};

export default InsuranceInquiry;
